@import '../../global.scss';
 
 .menu{
     width: 300px;
     height: 100vh;
     background-color: #0F2D2E;
     position: fixed;
     top: 0;
     right: -500px;
     z-index: 2;
     padding: 5rem 1rem 2rem;
     opacity: 0.8;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     transition: all 1s ease;

     &.active{
         right: 0;
     }

     ul {
        margin: 0;
        padding: 0;
        list-style: none;
        color: white;
        width: 60%;
        
    
        li {
          margin-bottom: 25px;
          a {
            color:inherit;
            text-decoration: none;
          }
    
          &:hover {
            font-weight: 500;
          }
        }
      }
 }

