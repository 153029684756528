@import '../../global.scss';

.contact{
    background-color: #fafafa;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;
    

    @include mobile{
        flex-direction: column;
        align-items: center;
        height: auto;
        
    }

    h1{
        font-size: 50px;
        padding-bottom: 20px;
        // overflow-y: hidden;

        @include mobile{
            font-size: 20px;
            padding-bottom: 0;
        }
    }

    h3{
        font-size: 25px;
        font-weight: 400;
        // overflow-y: hidden; 

        @include mobile{
            font-size: 15px;
            padding:10px;
        }
    }

    .container{

        width: 90%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile{
            flex-direction: column;
        }
        
        .left{
            flex: 0.5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top:30px;
         

            h2{
                font-size: 25px;

                @include mobile{
                    font-size: 18px;
                }
            }



            .wrapper{

                width: 100%;
                display:flex;
                flex-direction: column;
                align-items: center;
                padding-top: 30px;

                .item{

                    display: flex;
                    align-items: center;
                    padding: 20px;

                    p{
                        
                        font-size: 15px;
                        padding-left: 30px;
                        
                        a{
                            text-decoration: none;
                            color: black;
                        }

                        @include mobile{
                            font-size: 14px;
                        }
                    }

                    img{
                        float:left;
                        width: 50px;

                        
                    }

                    .linkedin{
                        width: 75px;
                    }
                }

                
            }
            }
            
    
        .right{
            flex:0.5;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top:30px;
    
            h2{
                font-size: 25px;
                overflow-y: hidden;

                @include mobile{
                    font-size: 18px;
                }
            }
    
            form{
                width: 100%;
                height:100%;
                padding-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include mobile{
                    padding-top: 10px;
                    justify-content: flex-start;
                }
              
    
                input{
                    width: 300px;
                    height: 30px;
                    margin: 5px;
                    font-size: 14px;
    
                    @include mobile{
                        width: 200px;
                        height: 20px;
                        font-size: 12px;
                    }
                }
    
                textarea{
                    width: 300px;
                    height: 200px;
                    font-size: 14px;
    
                    @include mobile{
                        width: 200px;
                        height: 100px;
                        font-size: 12px;
                    }
                }
    
                button{
                    width: 150px;
                    height: 30px;
                    margin: 5px;
                    color: black;
                    background-color: #abe7db;
                    border: none;
                    border-radius: 10px;
                    font-weight: 500;
                    cursor: pointer;
    
                    &:focus{
                        outline: none;
                    }
                }
    
                span{
                    color: darkgreen
                }
            }
    
        }
    }


    .Toastify__toast-container{

        overflow-y: hidden;

        @include mobile{
            width: 100vw;
        }

        .Toastify__toast{
            background:#0F2D2E;
            color:#fd6e72;
            
            @include mobile{
                font-size: 12px;
            }
        }

        .Toastify__toast-body{
            margin: 0;
            display:flex;
            align-items: center;
            justify-content: center;
        }

        .Toastify__close-button {
            color: #fd6e72;
        }
        
        
      }
    
    
       
}