@import '../../global.scss';

.skills{
    background-color: #e6e6e6;
    // height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 90px;
  
    @include mobile{
        height:auto;
    }

    .container{
        width:80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @include mobile{
            width: 100%;
        }

        .icon{
            margin: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include mobile{
                margin: 15px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }

            h3{
                font-size: 20px;

                @include mobile{
                    font-size: 12px;
                }
            }
        }

        img{
            width: 120px;

            @include mobile{
                width:84px;
            }

        }

      
    }

    h1{
        font-size: 50px;
        padding-bottom: 20px;
        // overflow-y: hidden;

        @include mobile{
            font-size: 20px;
            padding-bottom: 0;
        }
    }
}

#nodejs {
    width: 120px;

    @include mobile{
        width:84px;
        margin:15px;
    }

    img{
        margin-top: 30px;
        margin-bottom: 25px;

        @include mobile{
            margin-top: 20px;
            margin-bottom: 3px;
        }
        
    }
    
}

#expressjs{
    width: 144px;

    @include mobile{
        width:84px;
        margin:15px;
    }

    img{
        margin-top: 40px;
        margin-bottom: 30px;

        @include mobile{
            margin-top: 20px;
            margin-bottom: 8px;
        }
    }
    
}