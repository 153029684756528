@import '../../global.scss';

.aboutme{
    background-color:#e6e6e6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;

    @include mobile{
        height:auto;
        flex-direction: column;
        align-items: center;
        
    }

    h1{
        font-size: 50px;
        padding-bottom: 20px;

        @include mobile{
            font-size: 20px;
            padding-bottom: 0;
        }
    }

    .container{

            width: 90%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @include mobile{
                width: 100%;
                height:auto;
                flex-direction: column;
                align-items: center;
            }

        
    
        
    
            .left{
                flex:0.6;
                padding: 30px;
    
                @include mobile{
                    width: 90%;
                    height:auto;
                    padding: 10px; 
                }
    
                p{
                    font-size: 15px;
    
                    @include mobile{
                        font-size: 11px;
                    }
                }
            }
    
            .right{
                flex:0.4;
                display: flex;
                justify-content: center;
                // background-color: gold;
    
                .imageContainer{
                    width: 60%;
                    
                    @include mobile{
                        width: 40%;  
                    }

                    img{
                        width: 100%;
                        padding: 20px;
    
                        @include mobile{
                            width: 100%;
                            padding: 5px;
                        }
    
                    }
                }
            }
        

    }

    

    
}