@import '../../global.scss';


.intro{
    background-color:#fafafa;
    display: flex;
    justify-content: center;
    height: 100vh;

    @include mobile{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
   

    // .left{
    //     flex: 0.45;      

    //     .imgContainer{
    //         width: 100%;
    //         height:100%;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         float:right;
            
            

    //         @include mobile{
    //             height:70%;
                
    //         }

    //         img{
    //             height:60%;
    
    //             @include mobile{
    //                 height: 100%;
    //             }
    //         }
    //     }

        
    // }

    .right{
        // flex: 0.55;
        //whenever you use absolute the parent element must be relative positioned
        position: relative;
        // overflow: hidden;

        .wrapper{
            width: 600px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            

            @include mobile{
                padding-left:0;
                align-items: center;
                justify-content: flex-start;
            }

            h1{
                color:black;
                font-size: 60px;
                margin: 10px 0;

                @include mobile{
                    font-size: 30px;
                    margin: 0 0;
                }
            }
            h2{
                color: black;
                font-size: 35px;

                @include mobile{
                    font-size: 20px;
                    margin: 5px 0;
                   
                }
            }

            .container{
                
                display: flex;
                flex-wrap: wrap;

                @include mobile{
                    width: 250px;
                }

                h3{
                    color: black;
                    font-size: 30px;
    
                    @include mobile{
                        font-size: 18px;
                        margin: 0 0;
                    }
    
                    span{
                        font-size: 30px;
                        color: #fd6e72;
    
                        @include mobile{
                            font-size: 21px;
                        }
                    }
    
                    .ityped-cursor{
                        font-size: 2.2rem;
                        font-weight: 300;
                        animation: blink 1s infinite;
    
                        @include mobile{
                            font-size: 1.2rem;
                        }
                    }
                    @keyframes blink {
                        50%{
                            opacity: 1;
                        }
                        100%{
                            opacity: 0;
                        }
                        
                    }
    
                }
            }
            

            //  a{
            //      position: absolute;
            //      bottom: 10px;
            //      left: 50%;
    
    
            //      img{
            //          width: 30px;
                    
            //          animation: arrowBlink 2s infinite;
    
            //          @include mobile{
            //              width: 20px;
            //          }
            //      }
    
            //      @keyframes arrowBlink {
            //          100%{
            //              opacity: 0;
            //          }
            //      }
            //  }
        }

        
    }
}