@import '../../global.scss';

.projects{
    background-color: #fafafa;
    height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding-top: 90px;

    @include mobile{
        height:auto;
        
    }


    .container{
        width: 90%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        // padding-top: 90px;

        @include mobile{
            width: 100%;
        }

        h1{
            font-size: 50px;
            padding-bottom: 20px;
            // overflow-y: hidden;

            @include mobile{
                font-size: 20px;
                padding-bottom: 0;
            }
        }

        .wrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-top: 50px;
    
            @include mobile{
                margin-top: 20px;
                padding-top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .card{
                margin: 10px;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
                overflow: hidden;
                width: 400px;

                @include mobile{
                    width: 300px;
                }

                .card-header img {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                }

                .card-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    min-height: 250px;

                    h3{
                        font-size: 20px;

                        @include mobile {
                            font-size: 15px;
                        }
                    }

                    p{
                        font-size: 15px;

                        @include mobile{
                            font-size: 11px;
                        }
                    }

                    a{
                        text-decoration: none;
                        font-size: 15px;
                        color:#0F2D2E ;

                        @include mobile {
                            font-size: 12px;
                        }
                    }

                    
                    .hashtags{
                        display: flex;
                        flex-wrap: wrap;


                        a{
                            @include  mobile{
                            
                                overflow-y: hidden;
                            } 
                        }

                        .tag{
                            background: #fafafa;
                            border-radius: 50px;
                            font-size: 12px;
                            margin: 0;
                            color: #fd6e72;
                            padding: 2px 10px;
                            text-transform: uppercase;
                            cursor: pointer;
                            
                            
    
                            @include  mobile{
                                font-size: 10px;
                               
                            }
                        }
                    }
                    
                }

            }
        }

        // .wrapper{
        //     width: 100%;
        //     display: flex;
        //     justify-content: center;
        //     padding-top: 50px;

        //     @include mobile{
        //         margin-top: 20px;
        //         display: flex;
        //         flex-direction: column;
        //         align-items: center;
        //     }

        //     .item{
        //         width: 400px;
        //         border-radius: 20px;
        //         border:1px solid rgb(255, 255, 255);
        //         margin:20px 10px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         color:black;
        //         position:relative;
        //         transition: all 0.5s ease;
        //         cursor: pointer;

        //         @include mobile{
        //             width: 250px;
        //             height: 180px;
        //             margin:10px;
        //         }

        //         h3{
        //             position: absolute;
        //             top: 10px;
        //             font-size: 20px;

        //             @include mobile{
        //                 top: 5px;
        //                 font-size: 15px;

        //             }
        //         }

        //         a{
        //             text-decoration: none;
        //             color: black;
        //         }

        //         img{

        //             object-fit:cover;
        //             z-index: 1;
        //         }

        //         p{
        //             position: absolute;
        //             font-size: 15px;
        //             margin: 25px 15px 0 15px;

        //             @include mobile{
        //                 font-size: 11px;
        //                 margin: 30px 15px 0 15px;
        //             }
        //         }

        //         h5{
        //             font-size: 15px;
        //             font-weight: 700;
        //             margin-left: 33%;
        //             margin-top: 30px;

        //             @include mobile{
        //                 font-size: 11px;
        //                 margin-top:10px;
        //             }
        //         }

        //         &:hover{
        //             background-color:#fafafa;
        //             img{
        //                 opacity: 0.05;
        //                 z-index: 0;
        //             }

        //         }
        //     }
        // }


    }
}