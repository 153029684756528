@import '../../global.scss';

.topbar{
    width: 100%;
    height: 70px;
    background-color:#0F2D2E;
    position: fixed;
    top: 0;
    //z index bigger than other components
    z-index: 3;
    transition: all 1.5s ease;
    &::-webkit-scrollbar{
        display: none;
    }

    .wrapper{
        padding: 0 20px 20px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left{
            

            .logo{

                a{
                    padding: 0;
                }
                

                img{
                    width: 100%;
                    
                }
                
            }

            
        }

        .right{
            display: flex;
            align-items: center;

            .hamburger{
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction:column;
                justify-content: space-between;
                cursor: pointer;

                span{
                    width: 100%;
                    height: 3px;
                    background-color:#fd6e72;
                    transform-origin: left;
                    transition: all 1.5s ease;
                }
            }
        }
        
    }    
        
        
        
    &.active{
        background-color:#0F2D2E;
        color: #3EBBC3;
        opacity: 0.8;
        .hamburger{
            span{
                &:first-child{
                    background-color: #3EBBC3;
                    transform:rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    background-color: #3EBBC3;
                    transform:rotate(-45deg);
                }
            }
        }
                
    }
        
        
        
}


       
  











